@import "./styles/common";
@import "./styles/responsive";
@import "./styles/subscribe";
@import "./styles/fastActionNetwork";
@import "./styles/takeAction";
@import "./styles/otherIframe";
@import "./styles/events";
@import url('https://fonts.googleapis.com/css?family=Merriweather:300,700');


html.has-overlay {
  overflow-y: auto !important;
}

section.fastaction-modal {
  display: none !important;
  max-height: 449px;
}

section.is-active.fastaction-modal:not(#fastaction-signup) {
  display: block !important;
}

  .iframe-content, .external-embed {
    body, .select2-results__option {
    margin: 0;
    font-family: 'Open Sans', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  *:focus {
    outline: 2px solid $color-yellow !important;
    outline-offset: 1px;
    box-shadow: none !important;
  }


  h1, h2, h3, h4, h5, h6 {
    font-family: 'Montserrat', sans-serif !important;
    font-weight: bold;
  }

  label, select {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 1.63;

    @include desktop {
      font-size: 18px;
      line-height: 1.67;
    }
  }

  p {
    @include text();
  }

  footer.FooterHtml * {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 12px !important;
  }

  input, textarea, select {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 300 !important;
    border-radius: 0 !important;
    border: 1px solid #1d252c14;
    background-color: #f2f4f5;
    margin-bottom: 20px;
    font-size: 18px;

    &::placeholder {
      font-family: 'Open Sans', sans-serif !important;
      font-weight: 300 !important;
      color: $color-placeholder!important;
    }
  }

  input[type="text"], input[type="email"], input[type="tel"], select {
    height: 48px !important;
    padding: 0 20px !important;
    font-size: 18px;
    line-height: 30px;
  }

  .intl-tel-input.allow-dropdown input[type="tel"]  {
    padding-left: 52px !important;
  }

  textarea {
    min-height: 72px !important;
    padding: 17px 20px !important;
  }

  input[type=submit], button, .button {
    font-family: 'Montserrat', sans-serif !important;
    font-size: 15px;
    background-color: $color-yellow !important;
    color: $color-dark-grey !important;
    display: inline-block;
    position: relative;
    text-transform: uppercase;
    font-weight: bold !important;
    letter-spacing: 1px;
    transition: background-color 0.2s ease-in-out;
    margin: 0 !important;
    padding: 0 20px !important;
    border: 0 !important;
    min-width: 160px;
    height: 48px;

    &:hover {
      background-color: $color-accessibility-yellow !important;
    }

    &:after {
      content: " ";
      display: block !important;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 0;
      height: 0;
      border-bottom: 22px solid $color-accessibility-yellow;
      border-left: 22px solid transparent;
    }

    @include desktop {
      min-width: 220px;
      height: 60px;
    }
  }

  .select2-container {
    margin-top: 7px;
    margin-bottom: 0;
  }

  .select2-selection.select2-selection--single {
    padding: 3px 20px 0;
    display: flex;
    align-items: center;
    height: 48px;
    border-radius: 0;
    color: $color-dark-grey !important;
    font-size: 14px !important;
    font-weight: normal !important;
  }

  .select2-selection .select2-selection__rendered {
    color: $color-dark-grey !important;
    font-size: 14px !important;
    font-weight: normal !important;
  };

  .select2-dropdown {
    border-radius: 0;
  }

  .select2-results__option {
    height: 48px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    color: $color-dark-grey !important;
    font-size: 14px !important;
    font-weight: normal !important;
  }

  .select2-search__field {
    height: 48px !important;
    padding: 0 20px !important;
    color: $color-dark-grey !important;
    font-size: 14px !important;
    font-weight: normal !important;
  }

  .select2-results {
    padding: 0;
    margin: 0;
  }

  .select2-results__option--highlighted {
    color: $color-dark-grey !important;
    background-color: $color-grey-highlighted !important;
  }

  label.error {
    &:after {
      content: '';
      position: absolute;
      top: 40px;
      right: 16px;
      background-image: url('../../images/form-input-error.svg');
      background-repeat: no-repeat;
      display: block;
      width: 24px;
      height: 24px;
    }

    &.at-check {
      padding-right: 35px !important;

      &:after {
        top: 2px;
        right: 0;
      }
    }

    input {
      padding-right: 54px;
    }
  }

  label.authorize-payment-label.error {
    &:after {
      display: none;
    }
  }

  .ngp-form .at-inner {
    background-color: transparent;
  }

  .at-row>[class^="at-"] {
    flex: auto !important;
    margin: 0 !important;
    min-width: initial !important;

    &.at-check {
      padding: 3px 0;
    }
  }

  .at label {
    font-size: 14px;
    line-height: 1.57;

    input, select, textarea, .select2-container {
      margin-top: 7px !important;
      margin-bottom: 12px !important;
    }
  }

  .at label, .at label small {
    color: $color-white !important;

    .error {
      color: inherit !important;
    }
  }

  .at input[type="checkbox"], .at input[type="radio"] {
    & + span:before {
      border-radius: 0;
      border-color: rgba($color-white, .4);
      transition: all .3s ease-in-out;
    }
    &:hover {
      & + span:before {
        border-color: rgba($color-white, .6);
      }
    }

    &:focus,
    &:active {
      & + span:before {
        box-shadow: none;
        background-color: transparent;
        outline: 2px solid $color-accessibility-yellow !important;
        outline-offset: 1px;
        transition: none;
      }
    }
  }

  .at input[type="checkbox"]:checked, .at input[type="radio"]:checked {

    & + span:before {
      background-color: $color-accessibility-red;
      border-color: $color-accessibility-red;
    }

    & + span:after {
      content: '';
      font-size: 0 !important;
      color: transparent !important;
      background-image: url('../../images/icon-tick.svg');
      top: 7px;
      width: 10px;
      height: 8px;
    }
  }

  .at input[type="radio"] + span:before {
    height: 1.125rem;
    width: 1.125rem;
    background: transparent;
    border-radius: 50%;
    border: 1px solid #ced4da;
    color: inherit;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    top: 2px;
  }

  .at-check > span {
    color: $color-white;
  }

  .at .at-check input+span {
    margin-bottom: 0 !important;
  }

  .at-legend {
    display: none;
  }

  u {
    text-decoration: none !important;
  }


  .InterestsHeaderHtml p {
    font-size: 0.75rem;
  }

  .at-row.EmailAddress.MobilePhone {
    position: relative;
    padding-bottom: 1rem;

    #emailValidation {
      position: relative;
    
      @media only screen and (min-width: 700px) {
        position: absolute;
        bottom: -0.25rem;
      }
    }
  }

  .at-markup.SmsLegalDisclaimer.at-legal p {
    color: $color-white;
    font-size: 0.75rem;
  }
}


#emailValidation.error {
  margin-top: 0;
  padding: 0;
  color: $color-white;
}

.form--subscribe-box {
  #emailValidation.error {
    color: $color-black;
    text-align: left;
  }
}
