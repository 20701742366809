body {
  margin: 0;
}

.form--subscribe-box,
.form--subscribe-box--small,
.form--subscription--inline,
.form--subscription {
  .at input[type="checkbox"] {
    &:checked + span:before {
      border-color: $color-yellow !important;
      background-color: $color-yellow !important;
    }
  }

  .at-inner {
    background-color: transparent;
  }

  .at-markup.HeaderHtml {
    padding: 0;
    margin-bottom: 17px;

    h3 {
      color: $color-yellow;
      font-family: 'Noto Serif', sans-serif!important;
      font-size: 20px;
      font-weight: bold;
      font-style: italic;
      font-stretch: normal;
      line-height: 2.2;
      letter-spacing: -0.5px;
      margin: 0;
    }

    h2 {
      color: $color-white;
      font-family: 'Montserrat', sans-serif;
      font-size: 18px;

      @include title();
    }
  }

  .content.thankYou {
    color: $color-white;
    font-family: 'Open Sans', sans-serif;

    .label {
      color: $color-yellow;
      font-family: 'Noto Serif', sans-serif;
      font-weight: bold;
      font-style: italic;
      margin:0;
    }

    .header {
      font-family: 'Montserrat', sans-serif;
      font-weight: bold;
      margin:0;
    }

    .text {
      font-family: 'Open Sans', sans-serif;
      margin:0;
    }

    .caption {
      font-family: 'Montserrat', sans-serif;
      font-weight: bold;
      margin:0;
    }

    p {
      margin: 12px 0;
      font-size: 18px;
      line-height: 1.67;
    }
  }

  .at-fieldset.ContactInformation {
    padding: 0;

    .at-text.EmailAddress,
    .at-text.FirstName,
    .at-text.LastName,
    .at-text.PostalCode {
      padding: 1rem 0 0;
      margin: 0;
      font-size: 0;
      color: inherit !important;
      display: block;

      small {
        position: absolute;
        top: 0;
        opacity: 0;
        width: 0;

        &.didYouMean {
          width: auto;
          position: static;
          opacity: 1;
          margin: 0.5em 0 0;
          padding: 0;
          color: inherit !important;

          a {
            color: inherit;
          }
        }
      }
    }

    .at-row.EmailAddress,
    .at-row.FirstName,
    .at-row.LastName,
    .at-row.PostalCode {
      .error:after {
        top: calc(50% - 12px);
      }
    }
  }

  .at-submit.btn-at.btn-at-primary {
    font-family: 'Montserrat' !important;
    background-color: $color-yellow;
    color: $color-dark-grey;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-transform: uppercase;
    font-weight: bold !important;
    letter-spacing: 1px;
    transition: background-color 0.2s ease-in-out;
    border-radius: 0;
    margin: 0;
    border: 0;

    width: 160px!important;
    height: 48px!important;

    &:hover {
      background-color: $color-accessibility-yellow !important;
    }

    &:after {
      @include triangle($color-accessibility-yellow);
    }

    @include desktop {
      width: 220px!important;
      height: 60px!important;
      font-size: 15px!important;
    }
  }

  .at input[type=email],
  .at input[type=text],
  .at input[type=tel] {
    border: 1px solid $color-border-grey;
    border-radius: 0;
    margin: 0 !important;
    box-sizing: border-box;
    background-color: $color-light-grey;
    color: $color-dark-grey;
    text-align: left;
    font-size: 18px;
    line-height: 30px;
    &:focus {
      border-color: $color-input-focused;
      box-shadow: none;
    }
  }

  .at-form-submit {
    display: inline-block;
    margin-top: 20px;
    position: relative;
    padding: 0;

    &:after {
      @include triangle($color-accessibility-yellow);
    }
  }

  .at-markup {
    h2 {
      color: $color-yellow;
      font-size: 18px;
      margin-bottom: 10px;
    }

    p {
      color: $color-white;
      margin-bottom: 16px;
    }
  }

  .at label.error {
    &:after {
      top: 0;
    }
  }

  .YesSignMeUpForUpdatesForBinder {
    min-height: 24px;

    &.error:after {
      position: absolute;
      right: 16px;
    }
  }

  .at .at-check input + span {
    text-align: left;
    max-width: calc(100% - 40px);
  }

  .at-row-full, .at-row-full>div:first-child {
    width: 100%;
    position: relative;
    top: 5px;
    margin-top: 8px;
    margin-bottom: 5px;
  }

  footer {
    font-family: 'Open Sans';
    color: $color-white !important;
    padding: 0;
    font-size: 12px;
    line-height: 1;
  }

  header.at-title,
  .at-legend {
    display: none;
  }
}

nav + section .form--subscription, .form--subscription--inline {
  .at-inner {
    text-align: left;
  }

  .form--mailchimp {
    position: relative;
  }

  .at-markup.HeaderHtml {
    margin: 0;

    h3 {
      font-size: 16px;
      font-weight: bold;
      font-style: italic;
      letter-spacing: -0.4px;
      line-height: normal;
    }

    h2 {
      font-size: 16px;
      font-weight: bold;
      line-height: 1.25;
      letter-spacing: 0.9px;
    }
  }

  .at-fieldset.ContactInformation {
    display: inline-block;
    min-width: 0;
    margin: 0;
  }

  label.EmailAddress {
    input::placeholder {
      font-size: 16px;
      line-height: 30px;
    }
  }

  .clearfix {
    margin-top: 10px;
    position: relative;
  }

  .at-form-submit {
    position: absolute;
    top: 0;
    margin: 0;
  }

  .at input[type=email],
  .at input[type=text],
  .at input[type=tel] {
    height: 48px!important;
    width: 300px!important;
    text-align: left!important;
    padding-left: 20px!important;
    padding-right: 20px!important;
  }

  .at-submit.btn-at.btn-at-primary {
    height: 48px!important;
    line-height: 48px!important;
    min-width: 160px!important;
    font-size: 14px!important;
    font-weight: bold!important;
    letter-spacing: 0.8px!important;
    width: auto!important;
  }

  .FooterHtml {
    padding: 0;
    margin: 0;
    max-width: none;
  }

  .at-fieldset .at-row.EmailAddress .error.error:after {
    top: 12px;
  }

  .content.thankYou {

    .label {
      font-size: 16px;
      font-weight: bold;
      font-style: italic;
      letter-spacing: -0.4px;
    }

    .header {
      font-size: 16px;
      font-weight: bold;
      line-height: 1.25;
      letter-spacing: 0.9px;
    }

    .caption {
      margin-top: 10px;
      font-size: 14px;
      line-height: 1.25;
      letter-spacing: 0.9px;
    }
  }
}

.form--subscribe-box--small,
.form--subscribe-box {
  $tablet: 300px;
  color: $color-black;

  .YesSignMeUpForUpdatesForBinder {
    min-height: 24px;
    &.at-check.YesSignMeUpForUpdatesForBinder {
      margin-right: 25px !important;
    }

    &.error:after {
      position: absolute;
      right: -9px;
      pointer-events: none;

      @include tablet-subscription-box {
        right: -25px;
      }
    }
  }

  .at .at-check input + span {
    text-align: left;
    max-width: calc(100% - 40px);

    @include tablet-subscription-box {
      text-align: left;
      max-width: 100%;
    }
  }

  .at-inner {
    text-align: center;
  }

  .at-markup.HeaderHtml {
    h2, h3 {
      color: $color-dark-grey;
    }

    h2 {
      line-height: 1.17;
      font-size: 28px;

      @media (min-width: #{$tablet}) {
        font-size: 36px;
      }
    }
  }

  .at-form-submit {
    margin-top: 36px;
  }

  .at input[type=email] {
    margin: 10px auto;
    max-width: 100%;
  }

  .at-row.EmailAddress {
    justify-content: center;
    color: $color-black;
  }
}

.form--subscribe-box {
  $tablet: 300px;
  margin-bottom: 0;

  .FooterHtml {
    display: none;
  }

  .at-inner {
    font-family: "Open Sans", sans-serif;

    .HeaderHtml {
      h2 {
        font-size: 28px;
        font-weight: bold;
        letter-spacing: 1px;
        margin-bottom: 8px;
      }

      h3 {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.25;
        letter-spacing: -0.5px;
        margin-bottom: 13px;
      }
    }

    p {
      @include text();
      color: $color-dark-grey;
      margin: 0;
    }

    label.EmailAddress {
      margin-top: 16px;
      margin-bottom: 20px;
    }

    @media (min-width: #{$tablet}) {

      .HeaderHtml {
        h2 {
          margin-bottom: 12px;
          font-size: 36px;
          font-weight: bold;
          line-height: 1.17;
        }

        h3 {
          font-size: 24px;
          line-height: 1.83;
          letter-spacing: -0.8px;
          margin-bottom: 12px;
        }
      }

      label.EmailAddress {
        margin-top: 28px;
        margin-bottom: 28px;
      }
    }
  }

  form.clearfix {
    width: 100%;
    max-width: 340px;
    margin: 0 auto;
  }

  .at input[type=email],
  .at input[type=text],
  .at input[type=tel] {
    max-width: 340px;
    width: 100%;
    height: 52px !important;
    margin: auto !important;


    @media only screen and (min-width: $tablet) {
      height: 60px !important;
    }
  }

  .content.thankYou {
    color: $color-dark-grey;
    text-align: center;


    .label {
      color: $color-dark-grey;
      margin:0 0 12px;
      font-size: 20px;
      line-height: 1.83;
      letter-spacing: -0.8px;

      @media only screen and (min-width: $tablet) {
        font-size: 24px;
      }
    }

    .header {
      margin: 0;
      font-size: 28px;
      line-height: 1.17;
      letter-spacing: 1px;

      @media only screen and (min-width: $tablet) {
        font-size: 36px;
      }
    }

    .text {
      margin: 12px 0 40px;
      @include text();

      @media only screen and (min-width: $tablet) {
        margin-left: 38px;
        margin-right: 38px;
      }
    }
    .caption {
      font-size: 20px;
      letter-spacing: 0.2px;

      @media only screen and (min-width: $tablet) {
        font-size: 22px;
      }
    }
    p {
      margin: 12px 0;
      font-size: 18px;

      @media only screen and (min-width: $tablet) {
        font-size: 16px;
        line-height: 1.63;
      }
    }
  }
}

.form--subscribe-box--small {
  margin-bottom: 0;

  .at-inner {
    padding: 48px 28px 10px;

    header.HeaderHtml {
      margin-bottom: 16px;

      h3 {
        font-size: 16px;
        font-weight: bold;
        font-style: italic;
        line-height: 1.25;
        letter-spacing: -0.5px;
        margin-bottom: 13px;
        color: $color-dark-grey;
      }

      h2 {
        font-size: 28px;
        font-weight: bold;
        letter-spacing: 1px;
        color: $color-dark-grey;
        margin-bottom: 12px;
      }
    }

    p {
      font-size: 16px;
      line-height: 1.63;
      color: $color-dark-grey;
      margin: 0;
    }

    .YesSignMeUpForUpdatesForBinder.error {
      &:after {
        position: absolute;
        right: -10px;

        @media only screen and (min-width: 344px) {
          position: relative;
          right: -10px;
        }
      }
    }

    label.EmailAddress,
    label.FirstName,
    label.LastName,
    label.PostalCode {
      width: 100%;

      input {
        background-color: rgba(29, 37, 44, 0.08);
      }

      input::placeholder {
        font-size: 17px;
        line-height: 1.76;
        letter-spacing: 0.2px;
      }
    }
  }

  .at-form-submit {
    margin-top: 16px;
    margin-bottom: 25px;
  }

  .content.thankYou {
    padding: 48px 28px 10px;
  }

  @media (min-width: 400px) {
    .at-inner {
      padding-left: 60px;
      padding-right: 60px;
      text-align: left;

      header.HeaderHtml {

        h3 {
          font-size: 24px;
          letter-spacing: -0.8px;
          margin-bottom: 16px;
        }

        h2 {
          font-size: 28px;
          font-weight: bold;
          letter-spacing: 1px;
          margin-bottom: 16px;
        }
      }

      p {
        font-size: 16px;
        line-height: 1.63;
      }

      label.EmailAddress {
        input::placeholder {
          font-size: 18px;
          line-height: 1.67;
        }
      }
    }

    form.clearfix {
      width: 100%;
      max-width: 300px;
    }

    .at input[type=email] {
      width: 300px;
      height: 48px! important;
    }

    .content.thankYou {
      padding-left: 60px;
      padding-right: 60px;
    }
  }
}

.form--subscription {
  margin-bottom: 0;

  form.clearfix {
    width: 100%;
  }

  .at input[type=email] {
    width: 100%;
    margin: 0 !important;
  }

  .at input[type=text]:focus {
    border-color: rgba(255,255,255,0);
  }

  .at input[type=email]:focus,
  .at input[type=text]:focus,
  .at input[type="checkbox"]:focus + span:before {
    outline-offset: -2px !important;
  }

  .content.thankYou {
    margin-bottom: 88px;

    .label {
      font-size: 19px;
      letter-spacing: -0.5px;
      margin-bottom: 8px;
    }

    .header {
      line-height: 1.33;
      letter-spacing: 1px;
      font-size: 18px;
    }

    .caption {
      margin-top: 28px;
      font-size: 16px;
      line-height: 1.25;
      letter-spacing: 0.9px;
    }
  }

  .at-fieldset.ContactInformation {
    .at-row.EmailAddress {
      color: #fff;
    }
  }
}

.form--subscribe-box,
.form--subscribe-box--small,
.form--subscription {
  .at-fieldset.ContactInformation {
    .at-row.EmailAddress,
    .at-row.FirstName,
    .at-row.LastName,
    .at-row.PostalCode {
      .error input {
        padding: 8px !important;
      }
    }
  }
}

.light {
  .content.thankYou {
    color: $color-dark-grey;
  }
}

.form--mailchimp .at-row label {
  input[type='checkbox'], input[type='radio'] {
    margin-right: -13px;
    transform: translateY(3.5px);
    visibility: visible;
    opacity: 0;
    position: relative;
    left: -0;
    outline: 0;
  }
}

nav + section .form--subscription .form--mailchimp-dark .at-row label{
  input[type='checkbox'] {
    & + span {
      background: $color-category-menu-grey;
    }
  }
}

.form--subscribe-box,
.light {
  .YesSignMeUpForUpdatesForBinder span {
    color: $color-dark-grey !important;
  }

  .at input[type="checkbox"], .at input[type='radio'], .form--subscribe-checkbox {
    & + span:before {
      border-color: rgba($color-dark-grey, .4) !important;
    }
    &:hover {
      & + span:before {
        border-color: rgba($color-dark-grey, .6) !important;
      }
    }
    &:checked + span:before {
      border: none;
    }
  }
}
