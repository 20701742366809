$color-black: black;
$color-white: white;
$color-yellow: #ffb600;
$color-accessibility-yellow: #F29616;
$color-dark-grey: #1D252C;
$color-placeholder: #666B70;
$color-border-grey: rgba(29, 37, 44, 0.08);
$color-border-bottom: rgba(255, 255, 255, 0.2);
$color-medium-grey: #28333d;
$color-grey-hover: #2b3843;
$color-light-grey: #f2f4f5;
$color-grey-highlighted: #fafafa;
$color-panel-highlighted: #32404d;
$color-category-menu-grey: #394552;
$color-accessibility-red: #de3412;
$color-hover-red: #bd0b02;
$color-input-focused: #ffb600;
$color-note-yellow: #fdb714;

@mixin triangle($color) {
  content: "";
  display: block !important;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border-bottom: 22px solid $color;
  border-left: 22px solid transparent;
}

@mixin title() {
  font-family: 'Montserrat', serif;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}

@mixin text() {
  font-family: 'Merriweather', serif;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.5556;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

@mixin smallText() {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: normal;
}
