.form--other-iframe {
  .at,
  .at-title {
    color: $color-white;
  }

  .at {
    max-width: 747px;
  }

  header.at-title {
    @include title;
    font-size: 22px;
    margin-bottom: 1em;
    @include donate-form-tablet-portrait {
      font-size: 36px;
    }
  }

  .at-indented {
    margin-top: 0;
    padding-left: 0;
  }

  .HeaderHtml, .FooterHtml, .MeterHtml {
    padding: 0 0.625rem;
  }

  .ContactInformation {
    .at-row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: stretch;

      label {
        width: 100%;
        @media only screen and (min-width: 700px) {
          max-width: 340px;
        }
      }

      .selection .select2-selection,
      select {
        height: 48px;
        border-radius: 0;
      }

      input[type="text"], input[type="email"], input[type="tel"] {
        height: 48px !important;
      }
    }
  }
}
