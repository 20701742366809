$desktop: 895px;
$desktop-small: 1280px;
$tablet-landscape: 940px;
$tablet-portrait: 684px;
$mobile: 375px;
$desktop-subscribe-form: 375px;
$desktop-take-action-form: 859px;
$tablet-subscription-box: 279px;

@mixin donate-form-mobile {
  @media only screen and (min-width: $mobile) {
    @content;
  }
}

@mixin donate-form-tablet-portrait {
  @media only screen and (min-width: $tablet-portrait) {
    @content;
  }
}

@mixin donate-form-tablet-landscape {
  @media only screen and (min-width: $tablet-landscape) {
    @content;
  }
}

@mixin donate-form-desktop-small {
  @media only screen and (min-width: $desktop-small) {
    @content;
  }
}

@mixin donate-form-desktop {
  @media only screen and (min-width: $desktop) {
    @content;
  }
}

@mixin take-action-form-desktop {
  @media only screen and (min-width: $desktop-take-action-form) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: $desktop-subscribe-form) {
    @content;
  }
}

@mixin tablet-subscription-box {
  @media only screen and (min-width: $tablet-subscription-box) {
    @content;
  }
}
