.form--FAN {
  max-width: 859px;
  width: 100%;

  .at-error-console {
    background-color: $color-dark-grey;
  }

  .at-alert.at-error {
    margin-bottom: 0 !important;
  }

  .at {
    color: $color-white;
    font-family: "Open Sans", sans-serif;
  }

  .at-inner {
    background-color: transparent;
  }

  > header,
  .at-title {
    display: none;
  }

  .HeaderHtml {
    background-color: $color-dark-grey;
    margin-bottom: 0;
  }

  form {
    background-color: $color-dark-grey;

    input[type="text"], input[type="email"], input[type="tel"] {
      height: 48px !important;
      padding: 0 20px !important;
    }

    input {
      font-size: 16px !important;
    }
  }

  .at-markup.HeaderHtml h2 {
    @include title();
    font-size: 22px;
    line-height: normal;
    letter-spacing: 0.2px;
    color: $color-white;
    text-align: center;
    padding-top: 48px;
    padding-bottom: 40px;

    @include take-action-form-desktop {
      font-size: 28px;
      letter-spacing: 1px;
      padding-bottom: 52px;
      padding-top: 60px;
    }
  }

  section > form > fieldset.at-fieldset {
    padding: 0 24px;
    margin-bottom: 20px;

    @include take-action-form-desktop {
      padding: 0 56px;
    }
  }

  input[type=text], input[type=email], input[type=tel] {
    width: 100% !important;
    height: 48px !important;
    margin-bottom: 0 !important;

    @include take-action-form-desktop {
      width: 340px !important;
    }
  }

  input[type=email] {
   &::placeholder {
      color: transparent !important;
    }
  }

  .at-row {
    display: flex;
    margin: 0 auto;
    padding: 0;
    flex-direction: column;

    &:last-child {
      border-bottom: 1px solid rgba(255, 255, 255, .2);
      padding-bottom: 10px;
    }

    > label[class^="at-"] {
      flex: none !important;
    }

    label {
      width: 100%;
      flex: none !important;
      display: inline-block;
      vertical-align: top;
      margin-bottom: 12px !important;

      @include smallText();

      &:first-child {
        margin-left: 0 !important;
      }

      small, u {
        @include smallText();
      }

      small:not(.error) {
        text-transform: lowercase;
      }

      @include take-action-form-desktop {
        margin-left: 48px !important;
        width: 100%;
        margin-bottom: 0 !important;
        max-width: 340px;
      }
    }

    @include take-action-form-desktop {
      flex-direction: row;

      &:last-child {
        padding-bottom: 44px;
      }
    }
  }

  .at-text.PersonalUrl {
    display: none !important;
  }

  .didYouMean.info {
    display: none;
  }

  .at-form-submit {
    display: flex;
    justify-content: flex-end;
    margin: 0 24px;
    padding: 0 !important;
    position: relative;

    &:after {
      @include triangle($color-accessibility-yellow);
    }

    @include take-action-form-desktop {
      margin: 0 56px;
      margin-bottom: 10px;
      justify-content: flex-end;
    }
  }

  input[type=submit] {
    width: 100%;

    @include take-action-form-desktop {
      width: 218px;
    }
  }

  footer {
    display: flex;
    padding: 0 24px 80px;
    width: 100%;
    background-color: $color-dark-grey;
    height: auto;
    margin-bottom: 0;
    position: relative;
    > * {
      font-size: 16px !important;
    }

    @include donate-form-tablet-portrait {
      padding: 0 0 48px;
      background-color: transparent;

      > * {
        background: $color-dark-grey;
        padding-left: 56px;
        padding-right: 56px;
        margin: 0;
        width: 100%;
      }

      &:before,
      &:after {
        position: absolute;
        content: "";
        display: block;
      }

      &:before {
        z-index: -1;
        background-color: $color-dark-grey;
        left: 0;
        top: 0;
        bottom: 0;
        right: 48px;
        display: block !important;
      }

      &:after {
        bottom: 0;
        right: 0;
        border-top: 48px solid $color-dark-grey;
        border-right: 48px solid transparent;
      }

      p {
        padding-top: 16px;
      }
    }

    @include take-action-form-desktop {
      p {
        padding-top: 0;
      }
    }
  }

  small.error {
    max-width: 340px;
  }
}
