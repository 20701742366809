.take-action-from__back-button {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.57;
  color: $color-white;
  cursor: pointer;
  position: absolute;
  bottom: 64px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;

  @include donate-form-tablet-portrait {
    bottom: 140px;
    left: 56px;
    transform: none;
  }

  &:before {
    content: '';
    display: block;
    width: 44px;
    height: 44px;
    transform: rotate(90deg);
    background-image: url('../../images/arrow-expand-down.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.form--takeAction {
  .ngp-form {
    max-width: 100%;
  }

  header.at-title {
    color: $color-white;
    font-size: 22px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    padding-top: 48px !important;
    padding-bottom: 16px !important;

    @include take-action-form-desktop {
      padding-top: 60px !important;
    }
  }

  .at-error-console {
    background-color: $color-dark-grey;
  }

  .at-alert.at-error {
    margin-bottom: 0 !important;
  }

  header, p {
    margin: 0 !important;
  }

  header {
    padding: 0 24px !important;
    background-color: $color-dark-grey;
    display: flex;
    justify-content: center;

    p {
      color: $color-white !important;
      text-align: center;
      font-size: 18px;
      line-height: 1.67;
      padding-left: 8px;
      padding-right: 8px;

      @include take-action-form-desktop {
        max-width: 639px;
      }
    }
  }

  form {
    background-color: $color-dark-grey;
    padding-bottom: 29px;

    input[type="text"], input[type="email"], input[type="tel"] {
      height: 48px !important;
      padding: 0 20px !important;
    }
  }

  .at-markup.FastAction {
    display: none !important;
  }

  .UpdateMyProfile {
    display: none;
  }

  .ContactInformation {
    margin-top: 36px;
    padding: 0 24px !important;

    @include donate-form-tablet-portrait {
      padding-left: 56px !important;
      padding-right: 56px !important;
    }

    @include donate-form-tablet-landscape {
      padding-left: 80px !important;
      padding-right: 80px !important;
    }

    @include take-action-form-desktop {
      padding-left: 56px !important;
      padding-right: 56px !important;
    }

    .at-row {
      flex-direction: row;
      justify-content: space-between;

      >[class^="at-"] {
        width: 100%;
        max-width: 340px;

        @include donate-form-tablet-portrait {
          max-width: 278px;
        }

        @include take-action-form-desktop {
          max-width: 340px;
        }

        @include donate-form-tablet-landscape {
          max-width: 381px;
        }
      }
    }

    .at-fields {
      display: flex;
      flex-direction: column;

      .FirstName {
        order: 0;
      }

      .EmailAddress {
        order: 1;
      }

      .Country {
        order: 2;
      }
    }
  }

  .at-form-submit {
    border-top: 1px solid $color-border-bottom;
    display: flex;
    justify-content: flex-end;
    margin: 20px 24px 0;
    padding: 16px 0 0;
    position: relative;

    &:after {
      @include triangle($color-accessibility-yellow);
    }

    @include donate-form-tablet-portrait {
      margin-left: 56px !important;
      margin-right: 56px !important;
    }

    @include take-action-form-desktop {
      padding-top: 20px;
    }

    input {
      width: 100%;

      @include donate-form-tablet-portrait {
        width: auto;
        min-width: 220px;
      }
    }
  }

  .at-row input[type="checkbox"]:checked, .at-row input[type="radio"]:checked {
    & + span:before {
      background-color: $color-yellow !important;
      border-color: $color-yellow !important;
    }
  }

  footer {
    display: flex;
    padding: 0 24px 164px;
    width: 100%;
    background-color: $color-dark-grey;
    color: $color-white;
    height: auto;
    margin-bottom: 0;
    position: relative;
    > * {
      font-size: 16px !important;
    }

    @include donate-form-tablet-portrait {
      padding: 0 0 48px;
      background-color: transparent;

      > * {
        background: $color-dark-grey;
        padding-left: 56px;
        padding-right: 56px;
        margin: 0;
        width: 100%;
      }

      &:before,
      &:after {
        position: absolute;
        content: "";
        display: block;
      }

      &:before {
        z-index: -1;
        background-color: $color-dark-grey;
        left: 0;
        top: 0;
        bottom: 0;
        right: 48px;
        display: block !important;
      }

      &:after {
        bottom: 0;
        right: 0;
        border-top: 48px solid $color-dark-grey;
        border-right: 48px solid transparent;
      }
    }
  }
}
