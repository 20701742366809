@import url('https://fonts.googleapis.com/css?family=Montserrat:200');

.form--event {
  margin: 0 11px;
  padding: 42px 0;

  @include donate-form-desktop-small {
    padding: 75px 0;
  }
}

.at {
  overflow: visible !important;

  h1 {
    font-size: 36px;
    line-height: 1.08;
    text-transform: uppercase;

    @include donate-form-tablet-portrait {
      font-size: 48px;
    }
  }

  .oa-event-list-results-container {
    & > h3 {
      display: none;
    }

    & > br {
      display: none;
    }
  }

  .oa-pagination-container {
    padding: 3rem 0;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.67;

    ul.pagination {
      font-size: 16px;
      margin: 0;
      padding: 0;

      @include take-action-form-desktop {
        font-size: 18px;
      }

      li {
        display: none;

        @include donate-form-tablet-portrait {
          display: inline-block;
        }

        &.PagedList-skipToPrevious,
        &.PagedList-skipToNext {
          display: inline-block;
        }

        &:not([class*='PagedList']) {
          background: #e4e4e4;
          margin: 0 4px;
          padding: 6px 14px;

          a {
            color: inherit !important;
            padding: 6px 14px;
            margin: -6px -14px;
            display: block;

            &:hover {
              font-weight: 700;
            }
          }

          &.active {
            border-radius: 0;
            background-color: #ffb600;
          }
        }

        &.disabled > a {
          color: inherit;
          opacity: 0.5;
        }

        &[class*='PagedList']:not(.disabled) {
          a.oa-hooked {
            color: inherit;
            border-bottom: 2px solid #ffb600;
            display: inline-block;
            position: relative;
            white-space: pre;
            transition: background-color 0.2s ease-in-out;

            &:after {
              content: '';
              position: absolute;
              left: 0;
              bottom: 0;
              width: 100%;
              height: 2px;
              background-color: #ffb600;
            }

            &:hover {
              background-color: #ffb600;
            }
          }
        }
      }
    }

    .pagination-stats {
      font-size: 0.875em;
      margin-top: 1.5em;
    }
  }

  .oa-page-banner {
    padding: 54px 0 0;
    text-align: center;

    &-title {
      font-size: 28px;
      line-height: 1.17;
      text-transform: uppercase;
      font-family: 'Montserrat', sans-serif !important;
      font-weight: bold;
      display: block;

      @include donate-form-desktop-small {
        font-size: 36px;
      }
    }

    &-message {
      display: block;
      font-size: 18px;
      line-height: 1.67;
      font-family: 'Open Sans', sans-serif;
      margin: 1em 0;
    }
  }

  .oa-input-container {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    background: $color-light-grey;
    color: $color-dark-grey;

    .quick-filter-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: -15px;
      max-width: 800px;
      gap: 0.5em;

      @include take-action-form-desktop {
        flex-direction: row;
        justify-content: space-between;
      }

      .at-check,
      label {
        width: auto;
        padding: 0;
        color: inherit !important;

        & > span {
          color: inherit;
        }

        input[type='checkbox'] {
          & + span {
            &:before {
              border-color: $color-placeholder;
              background: white;
            }
            &:after {
              color: inherit;
            }
          }
          &:checked + span {
            &:before {
              border-color: $color-yellow;
              background: $color-yellow;
            }
            &:after {
              background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMSA0LjA3bDIuNjE2IDIuNTA0TDkgMSIgZmlsbC1ydWxlPSJub256ZXJvIiBzdHJva2U9IiMxRDI1MkMiIHN0cm9rZS13aWR0aD0iMiIgZmlsbD0ibm9uZSIvPjwvc3ZnPg==');
            }
          }
        }
      }

      & ~ * {
        display: none !important;
      }
    }

    h3 {
      text-transform: uppercase;
      color: inherit;
      margin-top: 0;
      display: block;
      width: 100% !important;
    }

    .oa-input-unit {
      padding: 10px 10px 0;

      @include donate-form-mobile {
        width: 50%;
      }

      @include donate-form-desktop-small {
        padding-top: 0;
      }
    }

    label {
      display: block;
      width: 100%;
      padding: 0 10px;

      @include donate-form-desktop-small {
        padding-bottom: 10px;
      }
    }

    & > div {
      width: 100%;

      @include donate-form-mobile {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
      }

      @include donate-form-tablet-portrait {
        width: 50%;
      }

      @include donate-form-desktop-small {
        width: 33%;
      }

      &.oa-input-unit {
        width: 100%;
      }
    }
  }

  .oa-map-render {
    margin-top: 32px;

    @include donate-form-desktop {
      height: 400px;
    }

    .mapboxgl-ctrl-group {
      border-radius: 0 !important;
    }

    .mapboxgl-ctrl-icon {
      padding: 0 !important;
      min-width: 0 !important;

      &:after {
        border-bottom: 12px solid #f29616 !important;
        border-left: 12px solid transparent !important;
      }
    }

    .mapboxgl-popup-close-button {
      min-width: 0 !important;
      padding: 0 !important;
      height: 30px !important;
      width: 30px !important;
      position: absolute !important;
      background: transparent !important;
      font-size: 18px !important;

      &:after {
        display: none !important;
      }
    }

    .mapboxgl-popup-title,
    .mapboxgl-popup-description {
      font-size: 15px;
      line-height: normal;
    }

    .oa-event-popup-name {
      font-family: Montserrat, sans-serif;
      font-size: 16px !important;
      text-transform: uppercase;
      color: inherit;
      border: 0 !important;
      display: block;
      margin: 5px 0;
    }
  }

  .ea-events-list {
    display: grid;
    grid-template-columns: minmax(230px, 327px);
    grid-gap: 22px;
    justify-content: center;
    margin-top: 32px;

    @include donate-form-tablet-portrait {
      grid-template-columns: repeat(2, 308px);
    }

    @include donate-form-desktop {
      grid-template-columns: repeat(auto-fill, minmax(286px, 1fr));
    }

    @include donate-form-desktop-small {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .ea-event {
    display: flex;
    flex-direction: column;
    font-family: 'Open Sans', sans-serif;

    a {
      color: inherit !important;
      display: block;
      background-image: linear-gradient(315deg, transparent 20px, #fff 20px);
      filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1));
      border-top: 10px solid #ddd;
    }

    /* Yellow */
    &[data-event-type='National Changemaker Call'] > a,
    &[data-event-type='NCMC'] > a {
      border-top-color: #FFB600;
    }
    
    /* Red */
    &[data-event-type='THL Virtual Action'] > a,
    &[data-event-type='Virtual Take Action'] > a,
    &[data-event-type='In-Person COVID Waiv'] > a {
      border-top-color: #FE3B1F;
    }

    /* Green */
    &[data-event-type='Virtual Book Club'] > a {
      border-top-color: #C2D500;
    }

    /* Blue */
    &[data-event-type='THL Virtual Social'] > a,
    &[data-event-type='Virtual Social'] > a {
      border-top-color: #00ABC8;
    }

    /* Grey */
    &[data-event-type*="Virtual Art Activism"] > a {
      border-top-color: #1D252C;
    }

    /* Teal */
    &[data-event-type*="Virtual Education"] > a {
      border-top-color: #009579;
    }
    
    &__recommended {
      font-family: Montserrat, sans-serif;
      font-size: 14px !important;
      text-transform: uppercase;
      font-weight: 700;
      margin: -1.2em 0 1.2em -20px;
      padding-left: 20px;
      background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiA+PHBhdGggZmlsbD0iI2ZmYjYwMCIgZD0iTTI1OS42OSA4NS45NjVsNDkuNjQ2IDIwLjcgMjAuNzAzIDQ5LjY0MmE2LjY1NyA2LjY1NyAwIDAwMTEuOTI2IDBsMjAuNzAyLTQ5LjY0MyA0OS42NDUtMjAuN2E2LjY2NiA2LjY2NiAwIDAwMC0xMS45MjRsLTQ5LjY0NS0yMC43MDItMjAuNzAyLTQ5LjY0MWE2LjY1NyA2LjY1NyAwIDAwLTExLjkyNiAwbC0yMC43MDMgNDkuNjQxTDI1OS42OSA3NC4wNGE2LjY2NiA2LjY2NiAwIDAwMCAxMS45MjV6bTEwNC42MDQgMTgxLjMyOGwtMTA0Ljc5OS0xNS4yOTUtNDYuOTA3LTk1LjE5N2MtOC4zOS0xNi45OTgtMzIuNjg4LTE3LjIwMi00MS4xODggMGwtNDYuOTA4IDk1LjE5Ny0xMDQuNzk4IDE1LjI5NWMtMTguNzk4IDIuNzAzLTI2LjQwNyAyNS45MDUtMTIuNzA0IDM5LjJsNzUuOTA4IDczLjk5NS0xOCAxMDQuNTg3Yy0zLjIwNCAxOC45MDUgMTYuNjg3IDMzLjEwNyAzMy4yOTcgMjQuMjAxbDkzLjc5OS00OS4zODcgOTMuNzk4IDQ5LjM4N2EyMi45NTQgMjIuOTU0IDAgMDAzMy4yOTgtMjQuMjAxbC0xNy44OTEtMTA0LjU4NyA3NS44OTItNzMuOTk0YzEzLjYxLTEzLjI5NiA2LTM2LjQ5OC0xMi43OTctMzkuMnptMTQ0Ljc1OS00OC4wNjVsLTM5LjcxNC0xNi41NTctMTYuNTU5LTM5LjcxYTUuMzI3IDUuMzI3IDAgMDAtOS41NDEgMGwtMTYuNTYxIDM5LjcxLTM5LjcxMiAxNi41NThhNS4zMzQgNS4zMzQgMCAwMDAgOS41NGwzOS43MTIgMTYuNTYgMTYuNTYgMzkuNzA3YTUuMzI1IDUuMzI1IDAgMDA5LjU0MiAwbDE2LjU1OS0zOS43MDggMzkuNzE0LTE2LjU2YTUuMzM0IDUuMzM0IDAgMDAwLTkuNTR6Ii8+PC9zdmc+") no-repeat left top / 16px auto;
    }
    
    &__inperson {
      font-family: Montserrat, sans-serif;
      font-size: 14px !important;
      text-transform: uppercase;
      font-weight: 700;
      margin: -1.2em 0 1.2em -20px;
      padding-left: 20px;
      background: url("data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJ1c2VycyIgY2xhc3M9InN2Zy1pbmxpbmUtLWZhIGZhLXVzZXJzIGZhLXctMjAiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNjQwIDUxMiI+PHBhdGggZmlsbD0iY3VycmVudENvbG9yIiBkPSJNOTYgMjI0YzM1LjMgMCA2NC0yOC43IDY0LTY0cy0yOC43LTY0LTY0LTY0LTY0IDI4LjctNjQgNjQgMjguNyA2NCA2NCA2NHptNDQ4IDBjMzUuMyAwIDY0LTI4LjcgNjQtNjRzLTI4LjctNjQtNjQtNjQtNjQgMjguNy02NCA2NCAyOC43IDY0IDY0IDY0em0zMiAzMmgtNjRjLTE3LjYgMC0zMy41IDcuMS00NS4xIDE4LjYgNDAuMyAyMi4xIDY4LjkgNjIgNzUuMSAxMDkuNGg2NmMxNy43IDAgMzItMTQuMyAzMi0zMnYtMzJjMC0zNS4zLTI4LjctNjQtNjQtNjR6bS0yNTYgMGM2MS45IDAgMTEyLTUwLjEgMTEyLTExMlMzODEuOSAzMiAzMjAgMzIgMjA4IDgyLjEgMjA4IDE0NHM1MC4xIDExMiAxMTIgMTEyem03Ni44IDMyaC04LjNjLTIwLjggMTAtNDMuOSAxNi02OC41IDE2cy00Ny42LTYtNjguNS0xNmgtOC4zQzE3OS42IDI4OCAxMjggMzM5LjYgMTI4IDQwMy4yVjQzMmMwIDI2LjUgMjEuNSA0OCA0OCA0OGgyODhjMjYuNSAwIDQ4LTIxLjUgNDgtNDh2LTI4LjhjMC02My42LTUxLjYtMTE1LjItMTE1LjItMTE1LjJ6bS0yMjMuNy0xMy40QzE2MS41IDI2My4xIDE0NS42IDI1NiAxMjggMjU2SDY0Yy0zNS4zIDAtNjQgMjguNy02NCA2NHYzMmMwIDE3LjcgMTQuMyAzMiAzMiAzMmg2NS45YzYuMy00Ny40IDM0LjktODcuMyA3NS4yLTEwOS40eiI+PC9wYXRoPjwvc3ZnPg==") no-repeat left 3px / 16px auto;
    }

    &__date {
      display: block;
      text-align: center;
      background: url(/static/media/event-background.b2fd99cd.svg) no-repeat center center / cover;
      height: 188px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &__day {
      font-size: 100px;
      line-height: 1;
      font-family: Montserrat, sans-serif;
      font-weight: 200;
      letter-spacing: -4.7px;
    }

    &__month-year {
      font-size: 15px;
      font-family: Montserrat, sans-serif;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    &__content {
      padding: 34px 28px;
      font-size: 16px;
      line-height: 1.63;
      display: block;
    }

    &__title {
      font-family: Montserrat, sans-serif;
      line-height: 1.15;
      font-size: 19px;
      letter-spacing: 0.2px;
      font-weight: 700;
      color: rgb(29, 37, 44);
      text-transform: uppercase;
      margin: 0 0 8px;
    }

    &__description {
      font-size: 0.9375em;
      line-height: 1.6;
      margin-bottom: 1.25em;

      &:empty {
        margin-bottom: -6px;
      }
    }

    &__details {
      font: inherit;
      font-size: 14px;
      line-height: 1.57;
      list-style: none;
      margin: 0;
      padding: 0;
    }

    &__detail {
      background-repeat: no-repeat;
      background-position: left 0.125em;
      margin-top: 8px;
      padding-left: 25px;
      background-size: auto 1.25em;

      &--location {
        background-image: url('data:image/svg+xml;base64,CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMzg0IDUxMiI+PHBhdGggZmlsbD0iI0QyRDNENSIgZD0iTTE3Mi4yNjggNTAxLjY3QzI2Ljk3IDI5MS4wMzEgMCAyNjkuNDEzIDAgMTkyIDAgODUuOTYxIDg1Ljk2MSAwIDE5MiAwczE5MiA4NS45NjEgMTkyIDE5MmMwIDc3LjQxMy0yNi45NyA5OS4wMzEtMTcyLjI2OCAzMDkuNjctOS41MzUgMTMuNzc0LTI5LjkzIDEzLjc3My0zOS40NjQgMHpNMTkyIDI3MmM0NC4xODMgMCA4MC0zNS44MTcgODAtODBzLTM1LjgxNy04MC04MC04MC04MCAzNS44MTctODAgODAgMzUuODE3IDgwIDgwIDgweiI+PC9wYXRoPjwvc3ZnPg==');
        font-weight: bold;
        background-position: 2px 0.125em;
      }
      &--date {
        background-image: url('data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZGF0YS1wcmVmaXg9ImZhcyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNDQ4IDUxMiI+PHBhdGggZmlsbD0iI0QyRDNENSIgZD0iTTAgNDY0YzAgMjYuNSAyMS41IDQ4IDQ4IDQ4aDM1MmMyNi41IDAgNDgtMjEuNSA0OC00OFYxOTJIMHYyNzJ6bTMyMC0xOTZjMC02LjYgNS40LTEyIDEyLTEyaDQwYzYuNiAwIDEyIDUuNCAxMiAxMnY0MGMwIDYuNi01LjQgMTItMTIgMTJoLTQwYy02LjYgMC0xMi01LjQtMTItMTJ2LTQwem0wIDEyOGMwLTYuNiA1LjQtMTIgMTItMTJoNDBjNi42IDAgMTIgNS40IDEyIDEydjQwYzAgNi42LTUuNCAxMi0xMiAxMmgtNDBjLTYuNiAwLTEyLTUuNC0xMi0xMnYtNDB6TTE5MiAyNjhjMC02LjYgNS40LTEyIDEyLTEyaDQwYzYuNiAwIDEyIDUuNCAxMiAxMnY0MGMwIDYuNi01LjQgMTItMTIgMTJoLTQwYy02LjYgMC0xMi01LjQtMTItMTJ2LTQwem0wIDEyOGMwLTYuNiA1LjQtMTIgMTItMTJoNDBjNi42IDAgMTIgNS40IDEyIDEydjQwYzAgNi42LTUuNCAxMi0xMiAxMmgtNDBjLTYuNiAwLTEyLTUuNC0xMi0xMnYtNDB6TTY0IDI2OGMwLTYuNiA1LjQtMTIgMTItMTJoNDBjNi42IDAgMTIgNS40IDEyIDEydjQwYzAgNi42LTUuNCAxMi0xMiAxMkg3NmMtNi42IDAtMTItNS40LTEyLTEydi00MHptMCAxMjhjMC02LjYgNS40LTEyIDEyLTEyaDQwYzYuNiAwIDEyIDUuNCAxMiAxMnY0MGMwIDYuNi01LjQgMTItMTIgMTJINzZjLTYuNiAwLTEyLTUuNC0xMi0xMnYtNDB6TTQwMCA2NGgtNDhWMTZjMC04LjgtNy4yLTE2LTE2LTE2aC0zMmMtOC44IDAtMTYgNy4yLTE2IDE2djQ4SDE2MFYxNmMwLTguOC03LjItMTYtMTYtMTZoLTMyYy04LjggMC0xNiA3LjItMTYgMTZ2NDhINDhDMjEuNSA2NCAwIDg1LjUgMCAxMTJ2NDhoNDQ4di00OGMwLTI2LjUtMjEuNS00OC00OC00OHoiLz48L3N2Zz4=');
        background-position: 1px 0.125em;
      }
      &--time {
        background-image: url('data:image/svg+xml;base64,CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHBhdGggZmlsbD0iI0QyRDNENSIgZD0iTTI1Niw4QzExOSw4LDgsMTE5LDgsMjU2UzExOSw1MDQsMjU2LDUwNCw1MDQsMzkzLDUwNCwyNTYsMzkzLDgsMjU2LDhabTkyLjQ5LDMxM2gwbC0yMCwyNWExNiwxNiwwLDAsMS0yMi40OSwyLjVoMGwtNjctNDkuNzJhNDAsNDAsMCwwLDEtMTUtMzEuMjNWMTEyYTE2LDE2LDAsMCwxLDE2LTE2aDMyYTE2LDE2LDAsMCwxLDE2LDE2VjI1Nmw1OCw0Mi41QTE2LDE2LDAsMCwxLDM0OC40OSwzMjFaIj48L3BhdGg+PC9zdmc+');
      }
    }
  }

  label .select2-container {
    margin-bottom: 0 !important;
    max-width: 100% !important;
  }

  .select2-container--default .select2-selection--multiple {
    border-radius: 0 !important;
    height: auto;
    min-height: 48px;
  }

  .select2-search--inline {
    width: auto;

    .select2-search__field {
      padding: 0 !important;
      outline: 0 !important;
    }
  }
  .select2-container .select2-search__field {
    margin: -2px 0px -5px !important;
    max-height: 48px;
  }

  .select2-container--default .select2-selection--multiple .select2-selection__choice {
    margin-top: 11px;
  }

  .read-more {
    text-align: center;
    padding: 3rem 0 1.5rem;

    &__link {
      text-transform: uppercase;
      color: #1d252c !important;
      font-weight: 700;
      transition: background-color 0.2s ease-in-out;
      border-bottom: 2px solid #ffb600;
      font-family: Montserrat, sans-serif;
      letter-spacing: 0.1px;
      text-decoration: none;

      &:hover {
        background-color: #ffb600;
      }
    }
  }
}

.iframe-content .select2-container--open,
.external-embed .select2-container--open {
  margin-top: 0 !important;
}

.iframe-content .ui-datepicker-title select {
  height: auto !important;
  padding: 0 !important;
}

.oa-input-container {
  color: #fff;
  background: #1d252c;
  margin-bottom: 24px;
  padding: 14px 24px 34px;

  @include donate-form-tablet-portrait {
    padding: 24px 34px 44px;
    margin-bottom: 54px;
  }

  @include donate-form-desktop {
    padding: 34px 44px 54px;
  }

  @include donate-form-desktop-small {
    padding-bottom: 44px;
  }

  .oa-textbox-icon {
    right: 16px !important;

    @include donate-form-tablet-portrait {
      transform: translate(0, 0) !important;
    }

    @include donate-form-desktop-small {
      transform: translate(0, -15%) !important;
    }
  }
}

.oa-event-result-container {
  .oa-event-result-datetime {
    .oa-event-result-starttime,
    .oa-event-result-endtime {
      text-transform: lowercase;
    }

    .oa-event-result-starttime {
      &::before {
        content: 'From ';
        text-transform: none;
      }

      & + span {
        display: none;
      }
    }

    .oa-event-result-endtime {
      &::before {
        content: ' to ';
      }
    }

    .oa-event-result-timezone {
      display: none;
    }
  }
}
